import CoreApi from '../core/core-api'
import { getAppManifest as coreGetAppManifest } from '../core/manifests/app-manifest'
import { serializeError } from '../../utils/utils'
import { editorAppMetaData } from './editor-app'
import * as Raven from 'raven-js'
import { EditorPlatformApp } from '@wix/platform-editor-sdk'

export const getAppManifest: EditorPlatformApp['getAppManifest'] = async () => {
  const api: CoreApi = await editorAppMetaData.getCoreApi()
  try {
    if (api.isADI()) {
      return {}
    }

    api.interactionStarted('get-app-manifest')

    const isResponsive = api.isResponsive()

    if (!editorAppMetaData.appManifest) {
      let isTopPremium = false

      try {
        const currentAscendPlan = await api.premium.getCurrentAscendPlan()
        isTopPremium = currentAscendPlan.isTopPremium
      } catch (ex) {}

      const withCorvidAPI = api.isExperimentEnabled('specs.crm.FormsEditorSubmitCorvidAPI')

      editorAppMetaData.appManifest = coreGetAppManifest({
        isTopPremium,
        isResponsive,
        withCorvidAPI,
      })
    }

    try {
      const filteredManifest = await api.filterAppManifestV2(editorAppMetaData.appManifest)

      // console.log('original', new Blob([JSON.stringify(editorAppMetaData.appManifest, null, 2)]).size / 1024, 'KB')
      // console.log(editorAppMetaData.appManifest)

      // console.log('filtered', new Blob([JSON.stringify(filteredManifest, null, 2)]).size / 1024, 'KB')
      // console.log(filteredManifest)

      api.interactionEnded('get-app-manifest')
      return filteredManifest
    } catch (err) {
      Raven.captureException(new Error('Failed to load filtered manifest'), {
        extra: { error: serializeError(err) },
      })

      return editorAppMetaData.appManifest
    }
  } catch (error) {
    Raven.captureException(new Error('get app manifest failed'), {
      extra: { error: serializeError(error) },
    })
  }
}
