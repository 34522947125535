import { GeneralUrl } from '../../general-fields/definitions/general-url'
import { FormsFieldPreset } from '../../../../../../constants/field-types'

export class CrmUrl extends GeneralUrl {
  public get fieldType() {
    return FormsFieldPreset.CRM_URL
  }

  protected get crmLabel() {
    return this.translate(`fieldTypes.${FormsFieldPreset.GENERAL_URL}`)
  }

  protected get data(): any {
    return {
      textType: 'url',
    }
  }
}
