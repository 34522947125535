import { FormsFieldPreset, RegistrationFieldPreset } from '../../../../../../constants/field-types'
import { FIELDS } from '../../../../../../constants/roles'
import { GeneralSingleCheckbox } from './general-single-checkbox'
import { iconNames } from '../../icons/types'
import { FormPlugin } from '../../../../../../constants/plugins'
import { FieldRenderConfigType } from '../../constants'
import { DEFAULT_CATEGORIES } from '../../../../../../panels/manage-fields-panel/constants/manage-fields-constants'
import { fieldManifest } from '../../field-utils'

export class GeneralSubscribe extends GeneralSingleCheckbox {
  public get fieldType() {
    return FormsFieldPreset.GENERAL_SUBSCRIBE
  }

  protected get icon() {
    return iconNames.subscribe
  }

  public get role() {
    return FIELDS.ROLE_FIELD_SUBSCRIBE
  }

  protected get data(): any {
    return {
      label: this.translate(`preset.${this.fieldType}Label`),
    }
  }

  protected get props(): any {
    return {
      required: false,
    }
  }

  protected get layout(): any {
    return {
      width: 290,
    }
  }

  public get allowOnlyOnce() {
    return true
  }

  protected get renderConfig() {
    return {
      [FormPlugin.FORM_BUILDER]: {
        crmSync: FieldRenderConfigType.REMOVE,
        addFieldPanelData: {
          category: DEFAULT_CATEGORIES.subscription,
          dependsOn: [FormsFieldPreset.EMAIL, RegistrationFieldPreset.REGISTRATION_FORM_LOGIN_EMAIL],
          hideTranslationPostfix: true,
          showTooltip: true,
          subCategory: DEFAULT_CATEGORIES.selection,
        }
      }
    }
  }

  public get manifest() {
    return fieldManifest({})
  }
}
