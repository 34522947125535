import translations from '../../../utils/translations'
import { ROLE_FORM } from '../../../constants/roles'
import { GFPP } from './manifest-commons'
import { AppStateObject } from '../app-state/app-state-builder'
import * as _ from 'lodash'
import { FormPlugin } from '../../../constants/plugins'
import { MessageType } from '../../../constants/field-types'
import { ControllerType } from '../../../constants/api-types'

const KEY_BY_MESSAGE_TYPE = {
  [MessageType.SUCCESS]: 'hiddenMessage',
  [MessageType.DOWNLOAD]: 'downloadFileMessage',
  [MessageType.REGISTRATION]: 'registrationFormMessage',
  [MessageType.MULTI_STEP_SUCCESS]: 'hiddenMessage',
}

const HELP_ID_BY_MESSAGE_TYPE = {
  [MessageType.SUCCESS]: '33f7c352-5c96-435b-82c5-68df326ff7e7',
  [MessageType.MULTI_STEP_SUCCESS]: 'a67013c1-f4ee-4e1d-bbac-e476e49e3b8a',
}

export const createDownloadMessageManifest = () => createMessageManifest(MessageType.DOWNLOAD)
export const createHiddenMessageManifest = (
  { plugins }: AppStateObject = {},
  controllerType: ControllerType
) => {
  const isRegistrationForm =
    _.includes(plugins, FormPlugin.REGISTRATION_FORM) ||
    controllerType === ControllerType.REGISTRATION_FORM

  const isMultiStepForm =
    _.includes(plugins, FormPlugin.MULTI_STEP_FORM) ||
    controllerType === ControllerType.MULTI_STEP_FORM

  const messageType = isRegistrationForm
    ? MessageType.REGISTRATION
    : isMultiStepForm
    ? MessageType.MULTI_STEP_SUCCESS
    : MessageType.SUCCESS
  return createMessageManifest(messageType)
}

const createMessageManifest = (messageType: MessageType) => {
  const messageKey = KEY_BY_MESSAGE_TYPE[messageType]

  return {
    relatedToRole: ROLE_FORM,
    displayName: translations.t(`manifest.${messageKey}.displayName`),
    behavior: { duplicatable: false },
    confirmOnDelete: {
      title: translations.t(`manifest.${messageKey}.confirmOnDelete.title`),
      contentText: translations.t(`manifest.${messageKey}.confirmOnDelete.contentText`),
    },
    gfpp: {
      desktop: {
        iconButtons: {
          connect: GFPP.REMOVE,
        },
        helpId: HELP_ID_BY_MESSAGE_TYPE[messageType],
      },
    },
  }
}
