import * as _ from 'lodash'
import { bindObjectFunctions, serializeError, parseInstance } from '../../utils/utils'
import translations from '../../utils/translations'
import Experiments from '@wix/wix-experiments'
import { createCollectionsApi as createWixCodeCollectionsApi } from '@wix/wix-code-collections-api'
import * as createEditorTransportLayer from '@wix/wix-code-collections-transport-editor'
import { initBiLoggerForEditorApp } from '../../utils/bi'
import RemoteApi from '../../panels/commons/remote-api'
import CoreApi from '../core/core-api'
import { editorAppMetaData } from './editor-app'
import { fetchAllThemes } from '../core/preset/themes-service'
import ExperimentsService from '../../utils/experiments-service'
import { PanelName } from '../core/manage-panels/consts/panel-names'
import { EVENTS } from '../../constants/bi'
import * as Raven from 'raven-js'
import { EditorPlatformApp } from '@wix/platform-editor-sdk'

// start app functions
const startApp = async (origin: Origin) => {
  const api = await editorAppMetaData.getCoreApi()
  const originType = _.get(origin, 'info.type')

  if (originType === 'APP_MARKET') {
    api.managePanels.openModalPanel(null, PanelName.ADD_FORM, () =>
      api.log({ evid: EVENTS.PANELS[PanelName.ADD_FORM].OPEN_PANEL })
    )
    return
  }

  const preset = _.get(origin, 'info.preset')

  if (!preset) {
    return
  }

  const containerRef = origin.info.containerRef

  if (originType === 'ADD_PANEL') {
    if (await api.addForm.preventFormAdditionAddPanel(preset, containerRef)) {
      return
    }

    return api.addForm.addForm(preset, {
      containerRef: origin.info.containerRef,
      source: originType,
      shouldSelectForm: _.get(origin, 'info.select_form', false),
    })
  }

  await api.addForm.addForm(preset, {
    containerRef,
    source: originType,
    createCollection: false,
    targetPageRef: _.get(origin, 'info.targetPageRef'),
    shouldSelectForm: _.get(origin, 'info.select_form'),
  })

  return api.saveSite()
}

// init functions
const initExperiments = async () => {
  const experiments: Experiments = new Experiments()
  await experiments.load('wix-form-builder')

  ExperimentsService.init(experiments)
  return experiments
}

export const initRaven = ({
  origin,
  msid,
  instanceId,
  instance,
}: {
  origin: Origin
  msid: string
  instanceId: string
  instance: string
}) => {
  const initiator = _.get(origin, 'initiator')
  let uuid = ''
  try {
    uuid = parseInstance(instance).uid
  } catch (e) {}

  Raven.setUserContext({ msid, id: instanceId, uuid })
  Raven.setTagsContext({ msid })

  if (initiator) {
    Raven.setTagsContext({ initiator })
  }

  return Raven
}

const initCollectionsApi = ({
  editorSDK,
  appDefinitionId,
}: {
  editorSDK: EditorSDK
  appDefinitionId: string
}) => {
  const transportLayer = createEditorTransportLayer(editorSDK, appDefinitionId)
  return createWixCodeCollectionsApi({ transportLayer, ownerAppId: appDefinitionId })
}

const initTranslations = async ({
  boundEditorSDK,
  experiments,
}: {
  boundEditorSDK: EditorSDK
  experiments: Experiments
}) => {
  const locale = await boundEditorSDK.environment.getLocale()
  return translations.init(locale, experiments.all())
}

const initApp = async ({
  appDefinitionId,
  instanceId,
  instance,
  editorSDK,
  origin,
  monitoring: { createFedopsLogger },
}: {
  appDefinitionId: string
  instanceId: string
  instance: string
  editorSDK: EditorSDK
  origin: Origin
  monitoring: { createFedopsLogger }
}) => {
  const fedopsLogger = createFedopsLogger()('wix-forms-editor-api')
  fedopsLogger.appLoadStarted()

  const [msid, siteId, experiments] = await Promise.all([
    editorSDK.info.getMetaSiteId(appDefinitionId),
    editorSDK.info.getSiteId(appDefinitionId),
    initExperiments(),
  ])

  const ravenInstance = initRaven({ origin, msid, instanceId, instance })
  const boundEditorSDK = bindObjectFunctions(editorSDK, appDefinitionId)
  const collectionsApi = initCollectionsApi({ editorSDK, appDefinitionId })
  const remoteApi = new RemoteApi({ boundEditorSDK, experiments, ravenInstance })
  const [biLogger] = await Promise.all([
    initBiLoggerForEditorApp(msid, origin.type),
    initTranslations({ boundEditorSDK, experiments }),
  ])

  const coreApi = new CoreApi(boundEditorSDK, editorSDK, {
    apis: {
      collectionsApi,
      remoteApi,
    },
    experiments,
    ravenInstance,
    biLogger,
    origin,
    appDefinitionId,
    fedopsLogger,
    siteId,
  })

  await boundEditorSDK.editor.setAppAPI(coreApi.generateRuntimeApi())

  coreApi.appState.setState()
  coreApi.steps.updateMultiStepFormsTitles()

  editorAppMetaData.setCoreApi(coreApi)
  fetchAllThemes(ravenInstance)

  fedopsLogger.appLoaded()
  editorAppMetaData.isInitialized = true
}

// exported function
export const editorReady: EditorPlatformApp['editorReady'] = async (
  editorSDK,
  appDefinitionId,
  payload
) => {
  try {
    const { origin } = payload
    // TODO: Do we need all of this in ADI? use origin.initiator === 'ADI' to skip stuff we don't need
    // TODO: Check where editorSDK is being called without pm-rpc (check console, have an error)
    if (!editorAppMetaData.isInitialized) {
      await initApp({
        appDefinitionId,
        instanceId: payload.initialAppData.instanceId,
        instance: payload.initialAppData.instance,
        editorSDK,
        origin: <Origin>origin,
        monitoring: (payload as any).monitoring, // they need to update the type
      })
    }
    return startApp(<Origin>origin)
  } catch (error) {
    Raven.captureException(new Error('editor ready failed'), {
      extra: { error: serializeError(error) },
    })
  }
}
