// TODO: find a better place for this file
export enum CATEGORIES {
  none = 'none',
  recommended = 'recommended',
  text = 'text',
  number = 'number',
  selection = 'selection',
  date = 'dateTime',
  upload = 'upload',
  subscription = 'subscription',
  registration = 'registration',
}

export enum DEFAULT_CATEGORIES {
  none = 'none',
  recommended = 'recommended',
  text = 'text',
  number = 'number',
  selection = 'selection',
  date = 'dateTime',
  upload = 'upload',
  subscription = 'subscription',
  recaptcha = 'recaptcha',
  signature = 'signature',
}

export const REGISTRATION_FORM_CATEGORY = 'registration'

export const MENU_ACTIONS_OVERRIDE = {
  toggleDefault: {
    enable: false,
  },
  duplicate: {
    enable: false,
  },
  toggleEditLabel: {
    enable: false,
  },
  toggleEditValue: {
    enable: false,
  },
  delete: {
    enable: false,
  },
}

export const FIELD_GROUPS = {
  CUSTOM: 'custom',
  CRM: 'crm fields',
  RECOMMENDED: 'recommended',
  REGISTRATION: 'registration',
}
