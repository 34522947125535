export const FORMS_SERVER = {
  URL: 'https://editor.wix.com/_api/wix-form-builder-web/v1',
  ENDPOINT: {
    EMAILS: {
      ADD: 'emails/add',
      GET: 'emails/get'
    },
    PUBLISH_SITE: 'publish-site',
    EDIT_DRAFT: 'edit-draft',
    RESTRICTIONS: 'premium/restrictions'
  }
}

export const FORMS_SERVERLESS = {
  URL: 'https://editor.wix.com/_serverless/forms-editor-service',
  ENDPOINT: {
    USERS: 'users'
  }
}

export const FEATURES_MANAGER_URL = 'https://editor.wix.com/_api/premium-features-manager/v1'
export const PLATFORMIZED_PAYMENTS_URL = 'https://editor.wix.com/_api/payment-services-web/payments/v2'
export const PREMIUM_STORE_URL = 'https://editor.wix.com/_api/store/v1'
