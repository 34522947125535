export enum BASE_DESIGN_GROUPS {
  FORM_BACKGROUND = 'form_background',
  FORM_BORDER_COLOR = 'form_border_color',
  FORM_BORDER_SIZE = 'form_border_size',
  FORM_CORNERS = 'form_corners',
  FORM_SHADOW = 'form_shadow',
  INPUT_BACKGROUND = 'input_background',
  INPUT_BACKGROUND_ERROR = 'input_background_error',
  INPUT_BACKGROUND_FOCUS = 'input_background_focus',
  INPUT_BACKGROUND_HOVER = 'input_background_hover',
  INPUT_BORDER_COLOR = 'input_border_color',
  INPUT_BORDER_COLOR_ERROR = 'input_border_color_error',
  INPUT_BORDER_COLOR_FOCUS = 'input_border_color_focus',
  INPUT_BORDER_COLOR_HOVER = 'input_border_color_hover',
  INPUT_BORDER_SIZES = 'input_border_sizes',
  INPUT_BORDER_SIZES_ERROR = 'input_border_sizes_error',
  INPUT_BORDER_SIZES_FOCUS = 'input_border_sizes_focus',
  INPUT_BORDER_SIZES_HOVER = 'input_border_sizes_hover',
  INPUT_CORNERS = 'input_corners',
  LABEL_TEXT_COLOR = 'label_text_color',
  LABEL_TEXT_FONT = 'label_text_font',
  MAIN_TEXT_COLOR = 'main_text_color',
  MAIN_TEXT_COLOR_ERROR = 'main_text_color_error',
  MAIN_TEXT_FONT = 'main_text_font',
  PLACEHOLDER_TEXT_COLOR = 'placeholder_text_color',
  LABEL_REQUIRED_TEXT_COLOR = 'label_required_text_color',
  INPUT_SHADOW = 'input_shadow',
}