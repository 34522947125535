import 'regenerator-runtime/runtime'
import { fetcher } from '../../utils/utils'
import { EditorPlatformApp } from '@wix/platform-editor-sdk'
import { editorReady } from './editor-ready'
import { onEvent } from './on-event'
import { getAppManifest } from './get-app-manifest'
import CoreApi from '../core/core-api'
import { getEditorSentryDSN, getAppVersion } from '../../utils/utils'
import * as Raven from 'raven-js'
import { generateExportedApi } from '../core/api-generator'

Raven.config(getEditorSentryDSN(), {
  logger: 'logger-editor-app', // TODO: We need to create another logger to ADI or somehow know in sentry that some errors come from ADI
  release: getAppVersion(),
})

class EditorAppMetaData {
  private coreApiResolver: DataResolver<CoreApi>
  public isInitialized: boolean
  public appManifest: any

  constructor() {
    this.isInitialized = false
    this.coreApiResolver = fetcher<CoreApi>()
  }

  getCoreApi(): Promise<CoreApi> {
    return this.coreApiResolver.getData
  }

  setCoreApi(coreApi: CoreApi): void {
    this.coreApiResolver.resolveData(coreApi)
  }
}

export const editorAppMetaData = new EditorAppMetaData()

export const formsEditorApp: EditorPlatformApp = {
  exports: generateExportedApi(editorAppMetaData, Raven),
  editorReady,
  onEvent,
  getAppManifest,
}
