import { FormsFieldPreset } from './field-types'
import { FormPlugin } from './plugins'

export type PremiumRestriction = {
  forms: { limit: number; threshold?: number }
  emails: { limit: number; threshold?: number }
  fields: { limit: number; threshold?: number }
  submissions: { limit: number; threshold?: number }
  steps: { limit: number; threshold?: number }
  allowedFields: {
    uploadButton?: boolean
    generalUploadButton?: boolean
    generalSignature?: boolean
  }
  allowedRedirections: { downloadFile?: boolean }
  allowedPlugins: { [FormPlugin.PAYMENT_FORM]?: boolean }
  isTopPremium?: boolean
}

export type Feature = {
  id: string
  uniqueName: string
  quotaFeature?: {
    limit: number
  }
  enabled: boolean
}

export enum upgradeAlertType {
  FORMS_LIMIT = 'formsLimit',
  FIELDS_LIMIT = 'fieldsLimit',
  FIELDS_DUPLICATE_LIMIT = 'fieldsDuplicateLimit',
  STEPS_LIMIT = 'stepsLimit',
  STEPS_DUPLICATE_LIMIT = 'stepsDuplicateLimit',
  UPLOAD_BUTTON = 'uploadButton',
  DOWNLOAD_REDIRECT = 'downloadRedirect',
  EMAILS_LIMIT = 'emailsLimit',
}

export const DEFAULT_RESTRICTIONS: PremiumRestriction = {
  forms: { limit: 5, threshold: 3 },
  fields: { limit: 10, threshold: 7 },
  submissions: { limit: 100, threshold: 70 },
  steps: { limit: 3, threshold: 2 },
  emails: { limit: 2, threshold: 2 },
  allowedRedirections: {},
  allowedFields: {},
  allowedPlugins: {},
}

export const getAscendPackagePickerUrl = (msid, appDefId, origin, vertical: string): string =>
  `https://www.wix.com/apps/upgrade?metaSiteId=${msid}&appDefId=${appDefId}&pp_origin=${origin}_${vertical}`

export const getAscendPackagePickerNewUrl = (
  msid: string,
  origin: string,
  vertical: string
): string =>
  `https://www.wix.com/ascend/plans?pp_origin=${origin}_${vertical}&metaSiteId=${msid}&originAppSlug=wix-forms`

export enum BillingPanelReferrer {
  GFPP = 'GPFF',
  MANAGE_FIELDS_LINK = 'manage_fields',
  ADD_FIELD_LINK = 'add_new_field',
  NUMBER_OF_FIELDS_ALERT = 'number_of_fields_alert',
  NUMBER_OF_STEPS_ALERT = 'number_of_steps_alert',
  NUMBER_OF_FORMS_ALERT = 'number_of_forms_alert',
  UPLOAD_FIELD_ALERT = 'upload_field_alert',
  DOWNLOAD_REDIRECT_ALERT = 'download_redirect_alert',
  ADI_UPLOAD_FIELD = 'adi_upload_field',
  ADI_PREMIUM_ALERT_SECTION = 'adi_premium_alert_section',
  DUPLICATE_FIELD_ALERT = 'duplicate_field_alert',
  NUMBER_OF_EMAILS_ALERT = 'number_emails_alert',
}

export const referrerByAlertType = {
  [upgradeAlertType.FORMS_LIMIT]: BillingPanelReferrer.NUMBER_OF_FORMS_ALERT,
  [upgradeAlertType.FIELDS_LIMIT]: BillingPanelReferrer.NUMBER_OF_FIELDS_ALERT,
  [upgradeAlertType.FIELDS_DUPLICATE_LIMIT]: BillingPanelReferrer.DUPLICATE_FIELD_ALERT,
  [upgradeAlertType.STEPS_LIMIT]: BillingPanelReferrer.NUMBER_OF_STEPS_ALERT,
  [upgradeAlertType.STEPS_DUPLICATE_LIMIT]: BillingPanelReferrer.NUMBER_OF_STEPS_ALERT,
  [upgradeAlertType.UPLOAD_BUTTON]: BillingPanelReferrer.UPLOAD_FIELD_ALERT,
  [upgradeAlertType.DOWNLOAD_REDIRECT]: BillingPanelReferrer.DOWNLOAD_REDIRECT_ALERT,
  [FormsFieldPreset.GENERAL_UPLOAD_BUTTON]: BillingPanelReferrer.UPLOAD_FIELD_ALERT,
  [upgradeAlertType.EMAILS_LIMIT]: BillingPanelReferrer.NUMBER_OF_EMAILS_ALERT,
}
