import * as _ from 'lodash'
import { FieldPreset } from '../../../../constants/field-types'
import { IGeneralField } from './general-field'
import { createRecommendedFields } from './recommended-fields'
import { createCustomFields } from './custom-fields'
import { createGeneralFields } from './general-fields'
import { createRegistrationFields } from './registration-form-fields'
import { createAdiFields } from './adi-form-fields'

export const fieldsTypes = (fieldsDefinitions) =>
  _.map(
    fieldsDefinitions,
    field => field.fieldType
  )

class FieldsStore {
  public allFieldsData: { [key in FieldPreset]?: IGeneralField }
  public recommendedFields: IGeneralField[]
  public customFields: IGeneralField[]
  public generalFields: IGeneralField[]
  public registrationFields: IGeneralField[]
  public adiFields: IGeneralField[]

  constructor() {
    this.recommendedFields = createRecommendedFields()
    this.customFields = createCustomFields()
    this.generalFields = createGeneralFields()
    this.registrationFields = createRegistrationFields()
    this.adiFields = createAdiFields()

    this.allFieldsData = _.reduce(
      [...this.recommendedFields, ...this.customFields, ...this.generalFields, ...this.registrationFields, ...this.adiFields],
      (acc, field: IGeneralField) => {
        acc[field.fieldType] = field
        return acc
      },
      {}
    )
  }
}

export const fieldsStore = new FieldsStore()