import { FormsExtendApi } from './extend-api'
import translations from '../../../utils/translations'
import CoreApi from '../core-api'
import {
  getApplicationNameByPreset,
  applicationNameToUrl,
} from '../../../panels/form-settings-panel/components/main/utils'
import { MAIN_APPLICATION_TYPES } from '../../../panels/form-settings-panel/constants'
import { EVENTS } from '../../../constants/bi'
import { FORMS_FEEDBACK } from '../../../panels/form-settings-panel/constants/support-urls'
import * as _ from 'lodash'
import { FOOTER_CTA_CLICKED } from './event-types'
import { ACTION_TYPE } from './action-types'

const DefaultFormApi = (coreApi: CoreApi): FormsExtendApi => {
  const appConfig = async ({ formComponentRef }): Promise<AppConfig> => {
    const msid = await coreApi.getMetaSiteId()

    const componentConnection = await coreApi.getComponentConnection(formComponentRef)
    const preset = _.get(componentConnection, 'config.preset')

    const applicationName: MAIN_APPLICATION_TYPES = getApplicationNameByPreset(preset)
    let cta
    switch (applicationName) {
      case MAIN_APPLICATION_TYPES.PAYMENT:
        cta = {
          type: ACTION_TYPE.SELECT_TAB,
          text: translations.t(`formSettings.mainTab.footer.${applicationName}.link`),
          tabName: 'payment',
        }
        break
      case MAIN_APPLICATION_TYPES.AUTOMATIONS:
        cta = {
          type: ACTION_TYPE.SELECT_TAB,
          text: translations.t(`formSettings.mainTab.footer.${applicationName}.link`),
          tabName: 'automations',
        }
        break
      default:
        const href = applicationNameToUrl(applicationName, msid)
        cta = {
          type: ACTION_TYPE.LINK,
          text: translations.t(`formSettings.mainTab.footer.${applicationName}.link`),
          href,
        }
    }

    return {
      features: {
        notifications: true,
        collection: true,
        contactsSync: true,
        payment: true,
        automations: true,
        doubleOptIn: true
      },
      mainCTA: {
        description: translations.t('formSettings.mainTab.mainActionContent'),
        actions: [],
      },
      secondaryCTA: {
        title: translations.t(`formSettings.mainTab.footer.${applicationName}.title`),
        subtitle: translations.t(`formSettings.mainTab.footer.${applicationName}.subtitle`),
        cta,
      },
      content: {
        formNameInfo: translations.t('formSettings.settingsTab.info'),
        onSubmitMessage: translations.t('settings.successMessage.default'),
      },
      feedbackUrl: FORMS_FEEDBACK,
    }
  }

  const onFooterCtaClicked = eventPayload => {
    const { formComponentRef, preset, origin, editorType, action } = eventPayload
    const applicationName: MAIN_APPLICATION_TYPES = getApplicationNameByPreset(preset)

    if (action.type === ACTION_TYPE.LINK) {
      coreApi.log({
        evid: EVENTS.PANELS.settingsPanel.OPEN_EXTERNAL_APP_LINK,
        form_comp_id: formComponentRef.id,
        template: preset,
        origin,
        application_name: applicationName,
        builderOrigin: editorType,
      })
    }
  }

  const onEvent = ({ eventType, eventPayload }) => {
    switch (eventType) {
      case FOOTER_CTA_CLICKED:
        onFooterCtaClicked(eventPayload)
        break
    }
  }

  return {
    onEvent,
    'settings.getCrucialElements': () => [],
    'fields.getNewFields': () => ({ add: [], remove: [] }),
    appConfig,
  }
}

export default DefaultFormApi
