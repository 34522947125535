import { AdiFieldPreset } from '../../../../../../constants/field-types'
import { GeneralText } from '../../general-fields/definitions/general-text'

export class AdiGeneralText extends GeneralText {
  public get fieldType() {
    return AdiFieldPreset.ADI_GENERAL_TEXT
  }

  public get mainCrmTypes() {
    return []
  }

  protected get data() {
    return {
      textType: 'text',
      placeholder: this.translate(`adi.fieldTypes.${this.base.metadata.fieldType}.placeholder`),
      label: this.translate(`adi.fieldTypes.${this.base.metadata.fieldType}.label`),
    }
  }

  protected get crmLabel() {
    return this.translate(`fieldTypes.${this.base.metadata.fieldType}`)
  }

  protected get ADI_titleTranslationKey() {
    return `adi.${this.base.metadata.fieldType}FieldEditPanel.title`
  }
}
