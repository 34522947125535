import * as _ from 'lodash'
import { FormField, FieldOption } from '../../constants/api-types'
import { PremiumRestriction } from '../../constants/premium'
import { FIELD_COMPONENT_TYPES, FieldPreset } from '../../constants/field-types'
import { fieldsStore } from '../../editor-app/core/preset/fields/fields-store'

const escapeRegExpSpecialCharcters = str =>
  str.replace(/[\-\[\]\/\{\}\(\)\*\+\?\.\\\^\$\|]/g, '\\$&')

const getDuplicateSuffix = (value: string, existingValues: string[], suffixTemplate: string) => {
  const templateParts = suffixTemplate ? suffixTemplate.split('{0}') : []
  const templateStart = templateParts[0] || '_'
  const templateEnd = templateParts[1] || ''
  const duplicateValueRegExp = new RegExp(
    `${escapeRegExpSpecialCharcters(value + templateStart)}(\\d+)${escapeRegExpSpecialCharcters(
      templateEnd
    )}`
  )

  const duplicateNumber =
    _(existingValues)
      .map(currValue => {
        const valueToTest =
          value === currValue ? `${currValue + templateStart}0${templateEnd}` : currValue
        const valueMatch = valueToTest.match(duplicateValueRegExp)

        return valueMatch ? parseInt(valueMatch[1], 10) : null
      })
      .filter(currValue => currValue !== null)
      .max() + 1

  return templateStart + duplicateNumber + templateEnd
}

const getUniqueValue = (value: string, existingValues: string[], suffixTemplate: string) => {
  if (!_.includes(existingValues, value)) {
    return value
  }

  return value + getDuplicateSuffix(value, existingValues, suffixTemplate)
}

export const filterNonLabelFields = (fields: Array<FormField>) => {
  return _.filter(
    fields,
    item => !fieldsStore.allFieldsData[item.fieldType].alwaysShowLabel
  )
}

export const getFieldsLeft = (fields: Array<FormField>, restrictions: PremiumRestriction) => {
  const { limit } = restrictions.fields
  return limit > -1 ? limit - fields.length : Infinity
}

export const shouldShowFieldLabel = (fieldType: FieldPreset) => !!_.get(fieldsStore.allFieldsData[fieldType], 'alwaysShowLabel')
export const isDateField = (fieldType: FieldPreset) => _.get(fieldsStore.allFieldsData[fieldType], 'properties.componentType') === FIELD_COMPONENT_TYPES.DATE_PICKER
export const isSingleCheckbox = (fieldType: FieldPreset) => _.get(fieldsStore.allFieldsData[fieldType], 'properties.componentType') === FIELD_COMPONENT_TYPES.SINGLE_CHECKBOX
export const isFileUploader = (fieldType: FieldPreset) => _.get(fieldsStore.allFieldsData[fieldType], 'properties.componentType') === FIELD_COMPONENT_TYPES.FILE_UPLOADER
export const isFieldWithOptions = (fieldType: FieldPreset) => !!_.get(fieldsStore.allFieldsData[fieldType], 'fieldWithOptions')

export const isFieldHasComplexPlaceholder = (field: FormField) => !!_.get(field, 'placeholder.text')
export const isCheckboxOption = fielOption => fielOption.checked !== undefined
export const createOptionValue = (options: FieldOption[], label: string) => {
  const SUFFIX_TEMPLATE = ' {0}'
  const existingValues = _.map(options, 'value')
  existingValues.push(label)

  return getUniqueValue(label, existingValues, SUFFIX_TEMPLATE)
}

export const getFieldName = fieldProperties => {
  const { label, placeholder, buttonLabel, showLabel, crmLabel, fieldType } = fieldProperties
  const realPlaceholder = isFileUploader(fieldType)
    ? buttonLabel
    : _.get(placeholder, 'text') || placeholder
  const labelTrimmed = _.trim(label)
  const placeholderTrimmed = _.trim(realPlaceholder)
  return (showLabel && labelTrimmed) || placeholderTrimmed || crmLabel
}
