import * as _ from 'lodash'
import { MAIN_APPLICATION_TYPES, PRESET_NAME_TO_APPLICATION_NAME } from '../../constants'

export const viewSiteMembersUrl = (msid) => `https://www.wix.com/dashboard/${msid}/member-permissions/members`

export const applicationNameToUrl = (applicationName, msid) => {
  return {
    [MAIN_APPLICATION_TYPES.AUTOMATIONS]: `https://www.wix.com/dashboard/${msid}/triggers`,
    [MAIN_APPLICATION_TYPES.WORKFLOWS]: `https://www.wix.com/dashboard/${msid}/workflows`,
    [MAIN_APPLICATION_TYPES.PRICE_QUOTES]: `https://www.wix.com/dashboard/${msid}/price-quotes`,
    [MAIN_APPLICATION_TYPES.GET_SUBSCRIBERS]: `https://www.wix.com/dashboard/${msid}/shoutout`,
  }[applicationName]
}

export const getApplicationNameByPreset = preset => {
  const footerTypeKey = Object.keys(PRESET_NAME_TO_APPLICATION_NAME).find(key => _.includes(preset, key))
  return footerTypeKey ? PRESET_NAME_TO_APPLICATION_NAME[footerTypeKey] : MAIN_APPLICATION_TYPES.AUTOMATIONS
}
