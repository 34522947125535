import { FormsFieldPreset } from '../../../../../../constants/field-types'
import { FIELDS } from '../../../../../../constants/roles'
import { GeneralSingleCheckbox } from '../../general-fields/definitions/general-single-checkbox'
import { FieldRenderConfigType } from '../../constants'
import { FormPlugin } from '../../../../../../constants/plugins'
import { DEFAULT_CATEGORIES } from '../../../../../../panels/manage-fields-panel/constants/manage-fields-constants'
import { fieldManifest } from '../../field-utils'

export class AgreeTerms extends GeneralSingleCheckbox {
  public get fieldType() {
    return FormsFieldPreset.AGREE_TERMS
  }

  public get role() {
    return FIELDS.ROLE_FIELD_AGREE_TERMS
  }

  protected get data(): any {
    return {
      label: this.translate(`preset.${this.fieldType}Label`),
    }
  }

  protected get props(): any {
    return {
      required: true,
    }
  }

  protected get layout(): any {
    return {
      width: 290,
    }
  }

  protected get renderConfig() {
    return {
      [FormPlugin.FORM_BUILDER]: {
        required: FieldRenderConfigType.DISABLED,
        internalName: FieldRenderConfigType.DISABLED,
        checkedByDefault: FieldRenderConfigType.DISABLED,
        addFieldPanelData: { category: DEFAULT_CATEGORIES.recommended }
      },
      [FormPlugin.REGISTRATION_FORM]: {
        addFieldPanelData: { category: DEFAULT_CATEGORIES.none }
      }
    }
  }

  public get manifest() {
    return fieldManifest({})
  }
}
