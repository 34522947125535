export enum LinkTypes {
  NONE = 'none',
  PAGE = 'page',
  LIGHTBOX = 'lightbox',
  EXTERNAL_LINK = 'ExternalLink',
  DOCUMENT_LINK = 'DocumentLink',
}

export enum mediaTypes {
  DOCUMENT = 'DOCUMENT',
  IMAGE = 'IMAGE',
}

export enum SettingsTabs {
  GENERAL = 'general',
  SUBMISSION = 'submission',
}

export const feedbackFooterHeight = 54
export const tabsHeight = 54
export const crucialElementHeight = 54
export const crucialElementsPadding = 18
