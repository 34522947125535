import { FIELD_COMPONENT_TYPES, FormsFieldPreset } from '../../../../../../constants/field-types'
import { FieldCollectionType } from '../../../../../../constants/wixcode'
import { FIELDS } from '../../../../../../constants/roles'
import { iconNames } from '../../icons/types'
import { IGeneralField } from '../../general-field'
import { FormPlugin } from '../../../../../../constants/plugins'
import { DEFAULT_CATEGORIES } from '../../../../../../panels/manage-fields-panel/constants/manage-fields-constants'
import { fieldManifest } from '../../field-utils'
import { BASE_DESIGN_GROUPS } from '../../../../manifests/constants'

export class GeneralRating extends IGeneralField {
  protected get icon() {
    return iconNames.rating
  }

  public get fieldType() {
    return FormsFieldPreset.GENERAL_RATING
  }

  public get collectionFieldType() {
    return FieldCollectionType.NUMBER
  }

  protected get componentType() {
    return FIELD_COMPONENT_TYPES.RATING
  }

  public get role() {
    return FIELDS.ROLE_FIELD_RATING
  }

  protected get skin() {
    return 'wixui.skins.Rating'
  }

  protected get layout() {
    return {
      width: 280,
      height: 89,
    }
  }

  protected get props() {
    return {
      type: 'RatingsInputProperties',
      showTitle: true,
      showLabels: true,
      labelPosition: 'top',
      labelAlignment: 'center',
      shapeSize: 32,
      shapeSpacing: 15,
      direction: 'ltr',
      required: false,
    }
  }

  protected get data(): any {
    return {
      type: 'RatingsInput',
      titleText: this.translate(`preset.${this.fieldType}Title`),
      svgId: '503047d3d3884f6097b82dec7af52fa8.svg',
      labels: {
        '1': this.translate(`preset.${this.fieldType}FirstOption`),
        '2': this.translate(`preset.${this.fieldType}SecondOption`),
        '3': this.translate(`preset.${this.fieldType}ThirdOption`),
        '4': this.translate(`preset.${this.fieldType}FourthOption`),
        '5': this.translate(`preset.${this.fieldType}FifthOption`),
      },
    }
  }

  protected get renderConfig() {
    return {
      [FormPlugin.FORM_BUILDER]: {
        addFieldPanelData: { category: DEFAULT_CATEGORIES.selection }
      }
    }
  }

  public get base() {
    return GeneralRating.prototype
  }

  public get manifest() {
    return fieldManifest({ duplicatable: true })
  }

  public get designMapping() {
    return {
      [this.componentType]: {
        [BASE_DESIGN_GROUPS.INPUT_BACKGROUND]: ['emptyShapeColor'],
        [BASE_DESIGN_GROUPS.LABEL_TEXT_COLOR]: [
          'labelFontColor',
          'filledShapeColor',
          'shapeBorderColor',
        ],
        [BASE_DESIGN_GROUPS.LABEL_TEXT_FONT]: ['labelFont'],
        [BASE_DESIGN_GROUPS.INPUT_BACKGROUND_ERROR]: ['errorFilledShapeColor'],
        [BASE_DESIGN_GROUPS.INPUT_BORDER_COLOR_ERROR]: ['errorShapeBorderColor'],
        [BASE_DESIGN_GROUPS.MAIN_TEXT_COLOR_ERROR]: ['errorLabelFontColor'],
        [BASE_DESIGN_GROUPS.INPUT_BACKGROUND_FOCUS]: ['focusEmptyShapeColor'],
        [BASE_DESIGN_GROUPS.INPUT_BORDER_COLOR_FOCUS]: ['focusShapeBorderColor'],
      }
    }
  }

  public get layoutComponentProps() {
    return [
      'labelAlignment',
      'shapeSpacing',
      'shapeSize',
      'labelPosition',
      'direction',
    ]
  }

  protected get styleProperties() {
    return {
      'properties': {
        'emptyShapeColor': '#FFFFFF',
        'errorFilledShapeColor': '#FFFFFF',
        'errorLabelFontColor': '#FF4040',
        'errorShapeBorderColor': '#FF4040',
        'errorShapeBorderWidth': '10',
        'filledShapeColor': '#FFCB05',
        'labelFont': 'normal normal 700 15px/1.4em futura-lt-w01-book',
        'labelFontColor': 'color_15',
        'shapeBorderColor': '#FFCB05',
        'shapeBorderWidth': '10',
        'focusShapeBorderWidth': '10',
      },
      'propertiesSource': {
        'emptyShapeColor': 'value',
        'errorFilledShapeColor': 'value',
        'errorLabelFontColor': 'value',
        'errorShapeBorderColor': 'value',
        'errorShapeBorderWidth': 'value',
        'filledShapeColor': 'value',
        'labelFont': 'value',
        'shapeBorderColor': 'value',
        'shapeBorderWidth': 'value',
      },
      'groups': {},
    }
  }
}
