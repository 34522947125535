import { FormsFieldPreset } from '../../../../../../constants/field-types'
import { GeneralUrl } from '../../general-fields/definitions/general-url'
import { CRM_TYPES, CRM_TAGS } from '../../../../../../constants/crm-types-tags'
import { FormPlugin } from '../../../../../../constants/plugins'
import { DEFAULT_CATEGORIES } from '../../../../../../panels/manage-fields-panel/constants/manage-fields-constants'

export class Website extends GeneralUrl {
  public get customFields() {
    return this.base.customFields
  }

  public get fieldType() {
    return FormsFieldPreset.WEBSITE
  }

  public get crmType() {
    return CRM_TYPES.WEBSITE
  }

  public get crmTag() {
    return CRM_TAGS.OTHER
  }

  protected get data() {
    return {
      textType: 'url',
      placeholder: this.translate(`fieldTypes.${this.fieldType}`),
    }
  }

  protected get renderConfig() {
    return {
      [FormPlugin.FORM_BUILDER]: {
        addFieldPanelData: { category: DEFAULT_CATEGORIES.recommended }
      },
      [FormPlugin.REGISTRATION_FORM]: {
        addFieldPanelData: { category: DEFAULT_CATEGORIES.none }
      }
    }
  }
}
