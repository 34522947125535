import { FIELDS } from '../../../../../../constants/roles'
import { RegistrationFieldPreset } from '../../../../../../constants/field-types'
import {  CheckboxAgreeTerms } from './checkbox-agree-terms'
import * as _ from 'lodash'
import { fieldManifest } from '../../field-utils'

export class PrivacyPolicy extends CheckboxAgreeTerms {
  public get fieldType() {
    return RegistrationFieldPreset.REGISTRATION_FORM_PRIVACY_POLICY
  }

  public get role() {
    return FIELDS.ROLE_FIELD_REGISTRATION_FORM_PRIVACY_POLICY
  }

  protected get data() {
    return {
      label: this.translate(`preset.${_.camelCase(this.fieldType)}Label`),
    }
  }

  public get manifest() {
    return fieldManifest({ registration: true })
  }
}
