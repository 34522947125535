import { FormsFieldPreset, FieldPreset, FIELD_COMPONENT_TYPES } from '../../../../../../constants/field-types'
import { FieldCollectionType } from '../../../../../../constants/wixcode'
import { FIELDS } from '../../../../../../constants/roles'
import { iconNames } from '../../icons/types'
import { IGeneralField } from '../../general-field'
import { FormPlugin } from '../../../../../../constants/plugins'
import { DEFAULT_CATEGORIES } from '../../../../../../panels/manage-fields-panel/constants/manage-fields-constants'
import { fieldManifest } from '../../field-utils'
import { baseDesignMappingValues } from '../../constants'
import * as _ from 'lodash'
import { BASE_DESIGN_GROUPS } from '../../../../manifests/constants'
import { TEXT_CRM_TYPES } from '../../../../../../constants/crm-types-tags'

export const makeGeneralDropdownOption = () => ({
  type: 'SelectOption',
  metaData: {
    isPreset: false,
    schemaVersion: '1.0',
    isHidden: false,
  },
  value: '',
  text: '',
  description: '',
  disabled: false,
})

export class GeneralDropdown extends IGeneralField {
  protected get icon() {
    return iconNames.dropdown
  }

  public get mainCrmTypes() {
    return TEXT_CRM_TYPES
  }

  public get fieldType(): FieldPreset {
    return FormsFieldPreset.GENERAL_DROP_DOWN
  }

  public get collectionFieldType() {
    return FieldCollectionType.TEXT
  }

  protected get componentType() {
    return FIELD_COMPONENT_TYPES.COMBOBOX
  }

  public get role() {
    return FIELDS.ROLE_FIELD_SELECT
  }

  protected get renderConfig() {
    return {
      [FormPlugin.FORM_BUILDER]: {
        addFieldPanelData: { category: DEFAULT_CATEGORIES.selection }
      }
    }
  }

  public get base() {
    return GeneralDropdown.prototype
  }

  public get manifest() {
    return fieldManifest({ duplicatable: true, keepMainAction2: true })
  }

  public get supportsLabel() {
    return true
  }

  public get supportedInResponsive() {
    return true
  }

  public get fieldWithOptions() {
    return true
  }

  protected get skin() {
    return 'ComboBoxInputSkin'
  }

  protected get responsiveSkin() {
    return 'ResponsiveComboBoxInputSkin'
  }

  protected get layout() {
    return {
      width: 240,
      height: 42,
    }
  }

  protected get props() {
    return {
      type: 'ComboBoxInputProperties',
      required: false,
      textAlignment: 'left',
      textPadding: 12,
    }
  }

  public get designMapping() {
    return {
      [this.componentType]: {
        ..._.omit(baseDesignMappingValues, BASE_DESIGN_GROUPS.MAIN_TEXT_COLOR),
        [BASE_DESIGN_GROUPS.MAIN_TEXT_COLOR]: ['txt', 'txt_placeholder', 'arrowColor'],
        [BASE_DESIGN_GROUPS.PLACEHOLDER_TEXT_COLOR]: ['txt', 'txt_placeholder', 'arrowColor'],
      },
    }
  }

  protected get data(): any {
    return {
      type: 'SelectableList',
      value: '',
      placeholder: {
        value: this.translate(`preset.${this.fieldType}PlaceholderValue`),
        text: this.translate(`preset.${this.fieldType}PlaceholderText`),
      },
      options: [
        {
          ...makeGeneralDropdownOption(),
          value: this.translate(`preset.${this.fieldType}FirstOptionsValue`),
          text: this.translate(`preset.${this.fieldType}FirstOptionsText`),
          description: this.translate(`preset.${this.fieldType}FirstOptionsText`),
        },
        {
          ...makeGeneralDropdownOption(),
          value: this.translate(`preset.${this.fieldType}SecondOptionsValue`),
          text: this.translate(`preset.${this.fieldType}SecondOptionsText`),
          description: this.translate(`preset.${this.fieldType}SecondOptionsText`),
        },
        {
          ...makeGeneralDropdownOption(),
          value: this.translate(`preset.${this.fieldType}ThirdOptionsValue`),
          text: this.translate(`preset.${this.fieldType}ThirdOptionsText`),
          description: this.translate(`preset.${this.fieldType}ThirdOptionsText`),
        },
        {
          ...makeGeneralDropdownOption(),
          value: this.translate(`preset.${this.fieldType}ForthOptionsValue`),
          text: this.translate(`preset.${this.fieldType}ForthOptionsText`),
          description: this.translate(`preset.${this.fieldType}ForthOptionsText`),
        },
      ],
    }
  }

  public get layoutComponentProps() {
    return ['textAlignment', 'textPadding', 'labelPadding', 'labelMargin']
  }

  protected get styleProperties() {
    return {
      'properties': {
        'alpha-arrowColor': '0.5',
        'alpha-bgd': '0',
        'alpha-brd': '0.55',
        'alpha-brdf': '1',
        'alpha-brdh': '0.9',
        'arrowColor': 'color_15',
        'bg': 'rgba(255, 255, 255, 1)',
        'bg2': '#aaaaaa',
        'bgd': '#D4D4D4',
        'bgh': 'color_11',
        'boxShadowToggleOn-shd': 'false',
        'brd': 'color_15',
        'brde': '#f60419',
        'brdf': 'color_18',
        'brdh': 'color_15',
        'brw': '1',
        'brwe': '1',
        'brwf': '1',
        'brwh': '1',
        'fnt': 'font_8',
        'rd': '0px',
        'shd': '0px 1px 4px 0px rgba(0,0,0,0.6)',
        'txt': 'color_15',
        'txt2': 'color_14',
        'txt_placeholder': 'color_15',
        'txtd': '#D4D4D4',
      },
    }
  }
}
