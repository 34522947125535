export enum FormPreset {
  CONTACT_FORM = 'contact-form',
  GET_SUBSCRIBERS = 'get-subscribers',
  PAYMENT_FORM = 'paymentForm01',
  POTENTIAL_CUSTOMERS = 'potential-customers',
  SALES_LEAD = 'sales-lead',
  SUPPORT_REQUEST = 'support-request',
  TESTIMONIAL = 'testimonial',
  JOB_APPLICATION = 'job-application',
  CUSTOMER_SATISFACTION = 'customer-satisfaction',
  PRICE_QUOTE = 'price-quote',
  CONTEST_ENTRY = 'contest-entry',
  QUIZ = 'quiz',
  MULTI_STEP_REGISTRATION_FORM = 'multi-step-registration-form',
  REGISTRATION_FORM = 'registration-form',
}

// TODO: use the same preset keys with different path in form resources
export enum ResponsiveFormPreset {
  CONTACT = 'responsiveContactForm02',
  SUBSCRIBERS = 'responsiveSubscribers02',
  SALES_LEAD = 'responsive-sales-lead',
  JOB_APPLICATION = 'responsive-job-application'
}

export enum FormPresetType {
  PAYMENT = 'payment',
}

export type FormPresetName = FormPreset | LIGHTBOX_PRESETS | STRIPS_PRESETS | string

export enum LIGHTBOX_PRESETS {
  LIGHTBOX_CONTACT01 = 'lightbox_contact01',
  LIGHTBOX_CONTACT02 = 'lightbox_contact02',
  LIGHTBOX_CONTACT03 = 'lightbox_contact03',
  LIGHTBOX_CONTACT04 = 'lightbox_contact04',
  LIGHTBOX_CONTACT05 = 'lightbox_contact05',
  LIGHTBOX_PROMOTION01 = 'lightbox_promotion01',
  LIGHTBOX_SUBSCRIBE01 = 'lightbox_subscribe01',
  LIGHTBOX_SUBSCRIBE02 = 'lightbox_subscribe02',
  LIGHTBOX_SUBSCRIBE03 = 'lightbox_subscribe03',
  LIGHTBOX_SUBSCRIBE04 = 'lightbox_subscribe04',
  LIGHTBOX_SUBSCRIBE05 = 'lightbox_subscribe05',
}

export enum STRIPS_PRESETS {
  STRIPS_CONTACT01 = 'strips_contact01',
  STRIPS_CONTACT02 = 'strips_contact02',
  STRIPS_CONTACT03 = 'strips_contact03',
  STRIPS_CONTACT04 = 'strips_contact04',
}
