export const enum PAYMENT_STATUS {
  UPGRADE_TO_ASCEND = 'upgrade-to-ascend',
  MISSING_PAYMENT_METHOD = 'missing-payment-method',
  CONNECTED = 'connected',
  GET_STARTED = 'get-started'
}

export const LEARN_MORE_HELP_ID = '817df5e6-fcbf-45e7-a18a-52e2dc53227b'

export enum BI_ACTIONS {
  OPEN_PAYMENT_OVERVIEW = 'Open Payment Overview',
  CHANGE_PAYMENT_METHOD = 'Change Payment Method',
  REMOVE_PAYMENT = 'Remove Payment from this site'
}
