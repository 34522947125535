import webBiLogger from '@wix/web-bi-logger'
import { BI } from '../constants/bi-common'
import { sanitizePII } from '@wix/bi-logger-sanitizer/dist/src/lib/sanitizers'
import { EditorType } from '@wix/platform-editor-sdk'
import { getAppVersion } from './utils'

const sanitizeBiLogger = (fields = {}) => {
  const sanitizedFields = {}

  Object.keys(fields).forEach(fieldName => {
    sanitizedFields[fieldName] = sanitizePII(fields[fieldName])
  })

  return sanitizedFields
}

export const initBiLogger = ({ endpoint = BI.ENDPOINT, src = BI.SRC, defaults = {} } = {}) => {
  const appVersion = getAppVersion()
  const biLogger = webBiLogger
    .factory({ endpoint })
    .setDefaults({ src, appVersion, ...defaults })
    .logger()
  return {
    log: fields => {
      const sanitizedFields = sanitizeBiLogger(fields)
      return biLogger.log(sanitizedFields)
    },
  }
}

export const initBiLoggerForEditorApp = async (msid, builderOrigin: EditorType) => {
  return initBiLogger({
    defaults: { msid, builderOrigin },
  })
}
