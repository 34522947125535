export enum iconNames {
  address = 'address',
  date = 'date',
  time = 'time',
  number = 'number',
  fileUpload = 'fileUpload',
  position = 'position',
  webaddress = 'webaddress',
  dropdown = 'dropdown',
  multiSelectCheckbox = 'multiSelectCheckbox',
  singleCheckbox = 'singleCheckbox',
  subscribe = 'subscribe',
  radioButton = 'radioButton',
  website = 'website',
  company = 'company',
  email = 'email',
  name = 'name',
  phone = 'phone',
  shortText = 'shortText',
  registrationFrom = 'registrationFrom',
  rating = 'rating',
  recaptcha = 'recaptcha',
  link = 'link',
  password = 'password',
  membersAction = 'membersAction',
  block = 'block',
  birthday = 'birthday',
  paragraph = 'paragraph',
  signature = 'signature',
}
